import React from 'react';
import WorkoutsBuilder from './WorkoutsBuilder'; // Ensure correct import path
import ExerciseCard from './ExerciseCard'; // Ensure correct import path
import { Grid, Typography } from '@mui/material';
import WeeklyWorkouts from './WeeklyWorkouts';


interface ExerciseListProps {
  exerciseData: Exercise[];
}

export interface Exercise {
    id: number;
    title: string;
    description: string;
    videoUrl: string;
    image: string;
    isGymOnly: boolean;
    isHomeOnly: boolean;
    isBuildingMuscle: boolean;
    isCardio: boolean;
    isBeginnerSafe: boolean;
    isIntermediatePro: boolean;
    musclesUsed: string[];
    // Add other properties as needed
  }

interface WorkoutFilters {
  isGymOnly: boolean;
  isHomeOnly: boolean;
  isBuildingMuscle: boolean;
  isCardio: boolean;
  isBeginnerSafe: boolean;
  isIntermediatePro: boolean;
}

const WorkoutsList: React.FC<ExerciseListProps> = ({ exerciseData }) => {
  const [filters, setFilters] = React.useState<WorkoutFilters>({
    isGymOnly: true,
    isHomeOnly: false,
    isBuildingMuscle: true,
    isCardio: false,
    isBeginnerSafe: false,
    isIntermediatePro: true,
  });

  const [selectedDaysPerWeek, setSelectedDaysPerWeek] = React.useState<number>(3); // State for selected days per week

  const handleDaysPerWeekChange = (value: number) => {
    setSelectedDaysPerWeek(value);
  };


  const handleFilterChange = (updatedFilters: WorkoutFilters): void => {
    setFilters(updatedFilters);
  };

  const filteredExercises = exerciseData.filter((exercise) => {
    // Apply your filtering logic based on the filters state
    // Example: Check if the workout is for the gym only and bodybuilding
    return (
      (!filters.isGymOnly || exercise.isGymOnly) &&
      (!filters.isHomeOnly || exercise.isHomeOnly) &&
      (!filters.isBuildingMuscle || exercise.isBuildingMuscle) &&
      (!filters.isCardio || exercise.isCardio) &&
      (!filters.isBeginnerSafe || exercise.isBeginnerSafe) &&
      (!filters.isIntermediatePro || exercise.isIntermediatePro)
      // Add more conditions based on your filtering requirements
    );
  });

  
  // Calculate the number of workouts based on the selected days per week
  const dailyExercisesCount = Math.ceil(filteredExercises.length / selectedDaysPerWeek);

  return (
    <div>
      <WorkoutsBuilder filters={filters} onFilterChange={handleFilterChange} selectedDaysPerWeek={selectedDaysPerWeek} handleDaysPerWeekChange={handleDaysPerWeekChange} />
      <WeeklyWorkouts filteredExercises={filteredExercises} selectedDaysPerWeek={selectedDaysPerWeek} />
    </div>
  );
};

export default WorkoutsList;