import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faHeartPulse, faHouseChimney, faMedal, faRankingStar, faScaleBalanced, faWeightHanging } from '@fortawesome/free-solid-svg-icons';
import WorkoutsFilter from './WorkoutsFilter';

import DaysPerWeekSelector from './DaysPerWeekSelector';

const FilterContainer = styled(Grid)`
  margin-bottom: 20px;
`;

const DaySelectorBox = styled(Box)`
  width:100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding-top: 10px;
  text-align: center;
  background-color: #f5f5f5;
`;

export const HeaderTypography = styled(Typography)`
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const LabelTypography = styled(Typography)`
  font-weight: bold;
  margin-bottom: 10px;
`;


interface WorkoutsFilterProps {
  filters: WorkoutFilters;
  onFilterChange: (filters: WorkoutFilters) => void;
  selectedDaysPerWeek: number;
  handleDaysPerWeekChange: (value: number) => void;
}

interface WorkoutFilters {
  isGymOnly: boolean;
  isHomeOnly: boolean;
  isBuildingMuscle: boolean;
  isCardio: boolean;
  isBeginnerSafe: boolean;
  isIntermediatePro: boolean;
}

const WorkoutsBuilder: React.FC<WorkoutsFilterProps> = ({ filters, onFilterChange, selectedDaysPerWeek, handleDaysPerWeekChange  }) => {

  const handleFilterChange = (filter: keyof WorkoutFilters): void => {
    // Create a new object with the updated filters
    const updatedFilters = { ...filters };

    // Toggle the selected state of the clicked filter
    updatedFilters[filter] = !filters[filter];

    // Call the onFilterChange callback with the updated filters
    onFilterChange(updatedFilters);
  };

  return (
    <div>
      <FilterContainer container spacing={2}>
        <Grid container item xs={12} sm={6} md={2}>
          <DaySelectorBox>
            <DaysPerWeekSelector value={selectedDaysPerWeek} onChange={handleDaysPerWeekChange} />
          </DaySelectorBox>
        </Grid>
        <Grid container item xs={12} sm={6} md={3}>
        <WorkoutsFilter 
          headerText="Location" 
          filters={filters}
          handleFilterChange={handleFilterChange}
          filterOptions={[
            { label: 'Gym Workout', filterKey: 'isGymOnly', icon: faDumbbell },
            { label: 'Home Workout', filterKey: 'isHomeOnly', icon: faHouseChimney }
          ]}
        />
        </Grid>
        <Grid container item xs={12} sm={6} md={3}>
        <WorkoutsFilter
            headerText="Goal" 
            filters={filters}
            handleFilterChange={handleFilterChange}
            filterOptions={[
              { label: 'Build Muscle', filterKey: 'isBuildingMuscle', icon: faWeightHanging },
              { label: 'Cardio', filterKey: 'isCardio', icon: faHeartPulse }
            ]}
          />
        </Grid>
        <Grid container item xs={12} sm={6} md={4}>
        <WorkoutsFilter
            headerText="Skill Level" 
            filters={filters}
            handleFilterChange={handleFilterChange}
            filterOptions={[
              { label: 'Beginner Safe', filterKey: 'isBeginnerSafe', icon: faRankingStar },
              { label: 'Intermediate/Pro Level', filterKey: 'isIntermediatePro', icon: faMedal }
            ]}
          />
        </Grid>
      </FilterContainer>
    </div>
  );
};

export default WorkoutsBuilder;