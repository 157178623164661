import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WorkoutFilters } from './types'; // Import the type
import { LabelTypography } from './WorkoutsBuilder';

const FilterGroup = styled(Box)`
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  text-align: center;
  background-color: #f5f5f5;
`;

const FiltersContainer = styled(Box)`
  display: flex;
  justify-content: space-between; /* Align filter boxes with space between */
  gap: 10px; /* Optional: Add gap between filter boxes */
`;

const FilterBox = styled(Box)<{ $isSelected: boolean }>`
  flex: 1; /* Each box will take equal space */
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  background-color: ${({ $isSelected }) => ($isSelected ? '#ccc' : 'transparent')};
  text-align: center;

  &:hover {
    border: 1px solid black;
  }
`;

interface Filter {
    label: string;
    filterKey: keyof WorkoutFilters; // Use specific keys from WorkoutFilters
    icon: any;
  }

  interface WorkoutsFilterProps {
    filters: WorkoutFilters; // Use WorkoutFilters directly
    handleFilterChange: (filterKey: keyof WorkoutFilters) => void;
    filterOptions: Filter[];
    headerText: string;
  }

export default function WorkoutsFilter({ filters, handleFilterChange, filterOptions, headerText  }: WorkoutsFilterProps) {
  return (
    <FilterGroup>
        <LabelTypography>{headerText}</LabelTypography>
        <FiltersContainer>
            {filterOptions.map((filter, index) => (
                <React.Fragment key={index}>
                    <FilterBox
                    onClick={() => handleFilterChange(filter.filterKey)}
                    $isSelected={filters[filter.filterKey]}
                    >
                    <Typography>{filter.label}</Typography>
                    <FontAwesomeIcon icon={filter.icon} size="2x"/>
                    </FilterBox>
                </React.Fragment>
            ))}
        </FiltersContainer>
    </FilterGroup>
  );
}