import React from 'react'
import Container from '@mui/material/Container';
import { useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';

const ContainerStyled = styled(Container)<{ paddingBottom: number }>`
  padding: 10px 20px ${({ paddingBottom }) => paddingBottom}px 20px;
`;

export default function PageContainer({ children }: { children: React.ReactNode }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  let paddingBottom = 120; // Default for desktop
  if (isTablet) paddingBottom = 100; // For tablet
  if (isMobile) paddingBottom = 80; // For mobile

  return (
    <ContainerStyled maxWidth="xl" paddingBottom={paddingBottom}>
      {children}
    </ContainerStyled>
  );
}
