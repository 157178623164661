import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faHeartPulse, faHouseChimney, faMedal, faRankingStar, faScaleBalanced, faWeightHanging } from '@fortawesome/free-solid-svg-icons';

const FilterContainer = styled(Grid)`
  margin-bottom: 20px;
`;

const FilterBox = styled(Box)<{ $isSelected: boolean }>`
  width: calc(50% - 10px);
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  background-color: ${({ $isSelected }) => ($isSelected ? '#ccc' : 'transparent')};
  text-align: center;
  & svg{
    height: 50px;
  }
`;

const LabelTypography = styled(Typography)`
  font-weight: bold;
  margin-bottom: 10px;
`;


interface WorkoutsFilterProps {
  filters: WorkoutFilters;
  onFilterChange: (filters: WorkoutFilters) => void;
}

interface WorkoutFilters {
  isGymOnly: boolean;
  isHomeOnly: boolean;
  isBuildingMuscle: boolean;
  isCardio: boolean;
  isBeginnerSafe: boolean;
  isIntermediatePro: boolean;
}

const ExercisesFilter: React.FC<WorkoutsFilterProps> = ({ filters, onFilterChange }) => {
  const handleFilterChange = (filter: keyof WorkoutFilters): void => {
    // Create a new object with the updated filters
    const updatedFilters = { ...filters };

    // Toggle the selected state of the clicked filter
    updatedFilters[filter] = !filters[filter];

    // Call the onFilterChange callback with the updated filters
    onFilterChange(updatedFilters);
  };

  return (
    <div>
      <FilterContainer container spacing={2}>
        {/* <Grid container item xs={12} sm={6} md={12}>
        <div> filter = {JSON.stringify(filters)}</div>
        </Grid> */}
        <Grid container item xs={12} sm={6} md={4}>
          <FilterBox
            onClick={() => handleFilterChange('isGymOnly')}
            $isSelected={filters.isGymOnly}
          >
            <LabelTypography>
              Gym Workout
            </LabelTypography>
            <FontAwesomeIcon icon={faDumbbell} width="100%" height="100%"/>
          </FilterBox>
          <FilterBox
            onClick={() => handleFilterChange('isHomeOnly')}
            $isSelected={filters.isHomeOnly}
          >
              <LabelTypography>
                Home Workout
              </LabelTypography>
            <FontAwesomeIcon icon={faHouseChimney} width="100%" height="100%"/>
          </FilterBox>
        </Grid>
        <Grid container item xs={12} sm={6} md={4}>
          <FilterBox
            onClick={() => handleFilterChange('isBuildingMuscle')}
            $isSelected={filters.isBuildingMuscle}
          >
            <LabelTypography>
            Build Muscle
            </LabelTypography>
            {/* <FontAwesomeIcon icon={faScaleBalanced} width="100%" height="100%"/> */}
            <FontAwesomeIcon icon={faWeightHanging} width="100%" height="100%"/>
          </FilterBox>
          <FilterBox
            onClick={() => handleFilterChange('isCardio')}
            $isSelected={filters.isCardio}
          >
            <LabelTypography>
              Cardio
            </LabelTypography>
            <FontAwesomeIcon icon={faHeartPulse} width="100%" height="100%"/>
          </FilterBox>
        </Grid>
        <Grid container item xs={12} sm={6} md={4}>
          <FilterBox
            onClick={() => handleFilterChange('isBeginnerSafe')}
            $isSelected={filters.isBeginnerSafe}
          >
            <LabelTypography>
              Beginner Safe
            </LabelTypography>
            <FontAwesomeIcon icon={faRankingStar} width="100%" height="100%"/>
          </FilterBox>
          <FilterBox
            onClick={() => handleFilterChange('isIntermediatePro')}
            $isSelected={filters.isIntermediatePro}
          >
            <LabelTypography>
              Intermediate/Pro Level
            </LabelTypography>
            <FontAwesomeIcon icon={faMedal} width="100%" height="100%"/>
          </FilterBox>
        </Grid>
      </FilterContainer>
    </div>
  );
};

export default ExercisesFilter;