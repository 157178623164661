import React from 'react';
import { Box, Typography, Container, Grid, Paper } from '@mui/material';
import Cookies from 'js-cookie';

const CookiesPage: React.FC = () => {
  const cookies = Cookies.get();  // Fetch all cookies
  const parsedCookies: { [key: string]: any } = {};

  // Parse each cookie value to convert it from JSON string to JavaScript object (if applicable)
  for (const [key, value] of Object.entries(cookies)) {
    try {
      parsedCookies[key] = JSON.parse(value);
    } catch {
      parsedCookies[key] = value;
    }
  }

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Stored Cookies
        </Typography>
        <Grid container spacing={3}>
          {Object.entries(parsedCookies).map(([key, value]) => (
            <Grid item xs={12} md={3} key={key}>
              <Paper elevation={3} style={{ padding: '16px' }}>
                <Typography variant="h6" gutterBottom>
                  {key}
                </Typography>
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                  {JSON.stringify(value, null, 2)}
                </pre>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default CookiesPage;
