import React, { useState, useMemo } from 'react';
import { Typography, Box, TextField, Button, Grid, Paper } from '@mui/material';
import { DndContext, closestCenter, MouseSensor, KeyboardSensor, TouchSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { SortableItem } from '../components/SortableItem';
import { CSS } from '@dnd-kit/utilities';
import exercisesJSON from '../exercises.json';
import PageContainer from '../components/PageContainer';


const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];


// Ensure Exercise type is correctly defined
interface Exercise {
  id: number;
  title: string;
}

interface DayWorkout {
  day: string;
  exercises: Exercise[];
}

// A mock function to simulate adding an exercise to a day
const addExerciseToDay = (dayWorkouts: DayWorkout[], dayId: string, exercise: Exercise): DayWorkout[] => {
  return dayWorkouts.map(dayWorkout => {
    if (dayWorkout.day === dayId) {
      // Prevent adding duplicates
      if (!dayWorkout.exercises.find(ex => ex.id === exercise.id)) {
        return { ...dayWorkout, exercises: [...dayWorkout.exercises, exercise] };
      }
    }
    return dayWorkout;
  });
};

const CustomWorkoutsPage: React.FC = () => {
  const [selectedDays, setSelectedDays] = useState<string[]>(['Mon', 'Wed', 'Sat']);
  const [dayWorkouts, setDayWorkouts] = useState<DayWorkout[]>(selectedDays.map(day => ({ day, exercises: [] })));
  const [filterText, setFilterText] = useState('');

  const toggleDaySelection = (day: string) => {
    setSelectedDays((currentDays) =>
      currentDays.includes(day) ? currentDays.filter((d) => d !== day) : [...currentDays, day],
    );
  };


  const filteredExercises = useMemo(() => exercisesJSON.filter(exercise => 
    exercise.title.toLowerCase().includes(filterText.toLowerCase())), 
    [filterText]
  );

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    console.log(`Active ID: ${active.id}, Over ID: ${over?.id}`);
    
    if (over) {
        const activeExercise = exercisesJSON.find(ex => `exercise-${ex.id}` === active.id);
        if (!activeExercise) return;
    
        // // Check if the destination is a day
        // if (over.id.startsWith('day-')) { // Assuming days have IDs like 'day-Mon', 'day-Tue', etc.
        //   const dayId = over.id.replace('day-', ''); // Extract actual day ID
        //   setDayWorkouts(current => addExerciseToDay(current, dayId, activeExercise));
        // }
      }
  };

  return (
    <PageContainer>
      <Typography variant="h4" gutterBottom>Create Your Custom Workout Plan</Typography>

      <Box sx={{ mb: 2 }}>
        {daysOfWeek.map((day) => (
          <Button
            key={day}
            variant={selectedDays.includes(day) ? 'contained' : 'outlined'}
            onClick={() => toggleDaySelection(day)}
            sx={{ margin: '4px' }}
          >
            {day}
          </Button>
        ))}
      </Box>

      <TextField
        label="Search Exercises"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(e) => setFilterText(e.target.value)}
      />
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h6">All Exercises</Typography>
          <SortableContext items={filteredExercises.map(ex => `exercise-${ex.id}`)} strategy={verticalListSortingStrategy}>
            {filteredExercises.map((exercise, index) => (
              <SortableItem key={exercise.id} id={`exercise-${exercise.id}`} title={exercise.title} />
            ))}
          </SortableContext>
        </Box>
        <Grid container spacing={2}>
          {selectedDays.map((day) => (
            <Grid item xs={12} sm={6} md={4} key={day}>
              <Typography variant="h6">{day}</Typography>
              <Box id={day} sx={{ minHeight: '150px', border: '1px solid #ccc', padding: 2 }}>
                {dayWorkouts.find(dw => dw.day === day)?.exercises.map(exercise => (
                  <Paper key={exercise.id} sx={{ margin: '8px', padding: '8px' }}>
                    {exercise.title}
                  </Paper>
                ))}
              </Box>
            </Grid>
          ))}
        </Grid>
      </DndContext>
    </PageContainer>
  );
};

export default CustomWorkoutsPage;
