import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { LabelTypography } from './WorkoutsBuilder';
import { styled } from '@mui/system';

const FormControlStyled = styled(FormControl)`
  padding: 0 10px 10px 10px;

  & .MuiInputBase-root {
    font-size: 2rem;
  }
`;

const MenuItemStyled = styled(MenuItem)`
  font-size: 3rem;
`;

interface DaysPerWeekSelectorProps {
  value: number;
  onChange: (value: number) => void;
}


const DaysPerWeekSelector: React.FC<DaysPerWeekSelectorProps> = ({ value, onChange }) => {
  const handleChange = (event: SelectChangeEvent<number>) => {
    onChange(event.target.value as number);
  };

  return (
    <FormControlStyled fullWidth>
      <LabelTypography>
        <label>Days per Week</label>
      </LabelTypography>
      <Select value={value} onChange={handleChange}>
        {[...Array(7)].map((_, index) => (
          <MenuItemStyled key={index + 1} value={index + 1}>
            {index + 1}
          </MenuItemStyled>
        ))}
      </Select>
    </FormControlStyled>
  );
};

export default DaysPerWeekSelector;