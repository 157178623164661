import { Box, Typography } from '@mui/material'
import React from 'react'
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine } from 'victory'

type GraphDataProps = {
    date: string;
    weight?: number;
    bpm?: number;
    x?: number;
    y?: number;
    distance?: number;
  };

type LineGraphProps = {
    headerText:string, 
    XAxis:string, 
    XAxisLabel:string, 
    YAxis:string, 
    YAxisLabel:string
    data: GraphDataProps[]
}

export default function LineGraph({headerText, XAxis, XAxisLabel, YAxis, YAxisLabel, data}:LineGraphProps) {
  return (
    <>
    {/* container-type: size; */}
        <Box style={{ containerType: "size", textAlign: "center"}}>
            <Typography variant="body1">{headerText}</Typography>
        </Box>
        <VictoryChart
            title='hello'
            desc='yesss'
            name='bppm'
            // theme={VictoryTheme.material}
        >
            <VictoryAxis
                label={XAxisLabel}
                tickLabelComponent={<VictoryLabel angle={-45} textAnchor="end" />}
            />
            <VictoryAxis
                dependentAxis
                label={YAxisLabel}
                style={{ axisLabel: { padding: 40 } }}
            />
            <VictoryLine
                data={data}
                x={XAxis}
                y={YAxis}
            />
        </VictoryChart>
  </>
  )
}


