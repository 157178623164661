import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Button, Grid, Container, Box, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import Cookies from 'js-cookie';
import TrackExerciseBox from '../components/TrackExerciseBox'; // Adjust the import path as necessary
import workoutPlansData from '../workout_plans.json'; // Adjust the import path as necessary
import exercisesData from '../exercises.json'; // Adjust the import path as necessary

interface Exercise {
  id: number;
  title: string;
  description: string;
  videoUrl: string;
  image: string;
  isGymOnly: boolean;
  isHomeOnly: boolean;
  isBuildingMuscle: boolean;
  isCardio: boolean;
  isBeginnerSafe: boolean;
  isIntermediatePro: boolean;
  musclesUsed: string[];
}

interface WorkoutPlan {
  id: number;
  title: string;
  description: string;
  rating: number;
  exerciseIds: number[];
}

interface ProgressEntry {
  date: string;
  set: number;
  reps: string;
  weight: string;
}

interface PreviousValues {
  [setIndex: number]: { reps: string; weight: string };
}

interface PreviousValuesState {
  [exerciseId: number]: PreviousValues;
}

const TrackProgressPage: React.FC = () => {
  const { workoutId } = useParams<{ workoutId: string }>();
  const workoutPlan = workoutPlansData.find(workout => workout.id === parseInt(workoutId || '0', 10)) as WorkoutPlan;

  const [progressData, setProgressData] = useState<{ [key: number]: ProgressEntry[] }>({});
  const [currentDate, setCurrentDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [previousValues, setPreviousValues] = useState<PreviousValuesState>({});

  useEffect(() => {
    const loadProgress = () => {
      const data: { [key: number]: ProgressEntry[] } = {};
      exercisesData.forEach((exercise: Exercise) => {
        const savedData = Cookies.get(`exercise_${exercise.id}`);
        if (savedData) {
          data[exercise.id] = JSON.parse(savedData);
        }
      });
      setProgressData(data);

      // Initialize previous values for the current date
      const initialPreviousValues: PreviousValuesState = {};
      exercisesData.forEach((exercise: Exercise) => {
        const exerciseData = data[exercise.id];
        if (exerciseData && exerciseData.length > 0) {
          const currentDayData = exerciseData.filter(entry => entry.date === currentDate);
          const initialSets = currentDayData.reduce((acc: PreviousValues, entry) => {
            acc[entry.set - 1] = { reps: entry.reps, weight: entry.weight };
            return acc;
          }, {} as PreviousValues);
          initialPreviousValues[exercise.id] = initialSets;
        } else {
          initialPreviousValues[exercise.id] = { 0: { reps: '', weight: '' }, 1: { reps: '', weight: '' }, 2: { reps: '', weight: '' } };
        }
      });
      setPreviousValues(initialPreviousValues);
    };

    loadProgress();
  }, [currentDate]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;
    setCurrentDate(newDate);
  
    // Initialize default values if no data exists for the new date
    const newPreviousValues: PreviousValuesState = {};
  
    exercisesData.forEach((exercise: Exercise) => {
      const exerciseData = progressData[exercise.id];
      if (exerciseData) {
        const currentDayData = exerciseData.filter(entry => entry.date === newDate);
        const initialSets = currentDayData.reduce((acc: PreviousValues, entry) => {
          acc[entry.set - 1] = { reps: entry.reps, weight: entry.weight };
          return acc;
        }, {} as PreviousValues);
        newPreviousValues[exercise.id] = initialSets;
      } else {
        newPreviousValues[exercise.id] = { 0: { reps: '', weight: '' }, 1: { reps: '', weight: '' }, 2: { reps: '', weight: '' } };
      }
    });
  
    setPreviousValues(newPreviousValues);
  };
  
  


  const handleInputChange = (exerciseId: number, setIndex: number, field: 'reps' | 'weight', value: string) => {
    const updatedProgress = [...(progressData[exerciseId] || [])];
    const entryIndex = updatedProgress.findIndex(entry => entry.date === currentDate && entry.set === setIndex + 1);

    if (entryIndex >= 0) {
      updatedProgress[entryIndex] = { ...updatedProgress[entryIndex], [field]: value };
    } else {
      updatedProgress.push({ date: currentDate, set: setIndex + 1, reps: field === 'reps' ? value : '', weight: field === 'weight' ? value : '' });
    }

    setProgressData({
      ...progressData,
      [exerciseId]: updatedProgress
    });
    Cookies.set(`exercise_${exerciseId}`, JSON.stringify(updatedProgress));
  };

  const handleSaveProgress = (exerciseId: number) => {
    const updatedProgress = [...(progressData[exerciseId] || [])];

    // Check if entries for the current date already exist
    const newEntries = [1, 2, 3].map(set => {
      const existingEntry = updatedProgress.find(entry => entry.date === currentDate && entry.set === set);
      if (!existingEntry) {
        return { date: currentDate, set, reps: '', weight: '' };
      }
      return null;
    }).filter(Boolean) as ProgressEntry[];

    if (newEntries.length > 0) {
      setProgressData({
        ...progressData,
        [exerciseId]: [...updatedProgress, ...newEntries]
      });
      Cookies.set(`exercise_${exerciseId}`, JSON.stringify([...updatedProgress, ...newEntries]));
    }
  };

  if (!workoutPlan) {
    return <Typography variant="h4">Workout plan not found</Typography>;
  }

  return (
    <PageContainer>
      <div>
        <Typography variant="h2">Track Your Progress for {workoutPlan.title}</Typography>
        <Grid container>
          <Grid xs={12} sm={6}>
              <TextField
                label="Date"
                type="date"
                value={currentDate}
                onChange={handleDateChange}
                fullWidth
                style={{ marginBottom: '16px' }}
              />
          </Grid>
          <Grid item xs={12} sm={6} textAlign='end'>
              <Button component={Link} to="/my-progress" variant='contained' color='primary'>
                  View Progress
              </Button>
          </Grid>
        </Grid>
       
        {workoutPlan.exerciseIds.map((exerciseId: number) => {
          const exercise = exercisesData.find(ex => ex.id === exerciseId);
          if (!exercise) return null;

          const previousValuesForExercise = previousValues[exerciseId] || { 0: { reps: '', weight: '' }, 1: { reps: '', weight: '' }, 2: { reps: '', weight: '' } };

          return (
            <TrackExerciseBox
              key={exerciseId}
              exercise={exercise}
              progressData={progressData}
              exerciseId={exerciseId}
              previousValuesForExercise={previousValuesForExercise}
              currentDate={currentDate}
              handleSaveProgress={handleSaveProgress}
              handleInputChange={handleInputChange}
            />
          );
        })}
      </div>
    </PageContainer>
  );
};

export default TrackProgressPage;
