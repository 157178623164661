import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Exercise } from '../types'; // Shared Exercise interface

const defaultImagePath = require('../assets/images/exercises/no_image_available.jpg'); // Full path to your default image

interface WorkoutCardProps {
    title: string;
    description: string;
    exercises: Exercise[];
    duration: number;
    onClick?: () => void; // Optional onClick handler for the card
}

const WorkoutCard: React.FC<WorkoutCardProps> = ({ title, description, exercises, duration, onClick }) => {
    const loadExerciseImage = (exercise: Exercise) => {
        try {
            return require(`../assets/images/exercises/${exercise.image}`);
        } catch (error) {
            console.error('Error loading image:', error);
            return defaultImagePath; // Fallback to default image
        }
    };

    return (
        <Card onClick={onClick} style={{ cursor: 'pointer' }}> {/* Apply onClick and make the card clickable */}
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Duration: {duration} minutes
                </Typography>
                <Grid container spacing={1} style={{ marginTop: '10px' }}>
                    {exercises.map((exercise, index) => (
                        <Grid item xs={4} key={exercise?.id || index}>
                            {exercise && (
                                <>
                                    <CardMedia
                                        component="img"
                                        height="100"
                                        image={loadExerciseImage(exercise)}
                                        alt={exercise.title}
                                    />
                                    {/* <Typography variant="body2" color="textSecondary" align="center">
                                        {exercise.title}
                                    </Typography> */}
                                </>
                            )}
                        </Grid>
                    ))}
                </Grid>
                {/* <Button variant="outlined" style={{ marginTop: '10px' }}>
                    View Workout
                </Button> */}
            </CardContent>
        </Card>
    );
};

export default WorkoutCard;
