import React from 'react'
import TextImageBackground from '../components/TextImageBackground';
import TextParagraph from '../components/TextParagraph';
import Container from '@mui/material/Container';
import TextImageLink from '../components/TextImageLink';
import TextImageCarousel from '../components/TextImageCarousel';
// import TextImageSideBySide from '../components/TextImageSideBySide';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import PageContainer from '../components/PageContainer'

//images
// import freeWorkoutsImage from '../assets/images/free_workouts.jpg';
import freeWorkoutsImage from '../assets/images/imageLink_freeWorkouts_desktop.jpg';
import storeImage from '../assets/images/imageLink_store_desktop.jpg';
import findPTImage from '../assets/images/imageLink_findPT_desktop.jpg';

import discountsImage from '../assets/images/discounts.jpg';
import gymNewsImage from '../assets/images/gym_news.jpg';
import bannerImage from '../assets/images/banner_image.jpg';
import bannerImageDesktop from '../assets/images/banner_image_desktop.jpg';

// images etsy
import blackTeeImage from '../assets/images/etsy/branded_tee_black.jpg';
import blueTeeImage from '../assets/images/etsy/branded_tee_blue.jpg';
import greyTeeImage from '../assets/images/etsy/branded_tee_grey.jpg';
import redTeeImage from '../assets/images/etsy/branded_tee_red.jpg';




const mobileSize = 12;
const tabletSize = 4;
const desktopSize = 4;

// const PageContainer = styled(Container)`
// `;


export default function HomePage() {
  return (
    <>

          <TextImageBackground
            backgroundImage={bannerImageDesktop}
            title="GYM PROGRESS"
            // subTitle="The heart of fitness."
            subTitle="Empowering Every Rep, Every Goal – Welcome to Your Fitness Future!"
            />
        <PageContainer >
          <TextParagraph
            title="Welcome to Gym Progress – Your Ultimate Fitness Companion!"
            paragraph="At Gym Progress, we believe that fitness goes beyond physical transformation. It's about building strength in both body and mind. Our platform is your gateway to a holistic fitness journey, offering free workouts, progress tracking, personal trainer connections, and a vibrant community. Whether you're working on your own goals or helping others achieve theirs, Gym Progress is here to support every step of your journey."
          />
          <Grid container spacing={2} mb={2}>
              <Grid item xs={mobileSize} md={tabletSize} lg={desktopSize}>
                <TextImageLink
                title='Free Workouts'
                subTitle='From a bodybuilding bulk, to a weight loss plan, we have you covered.'
                linkUrl='/workouts'
                backgroundImage={freeWorkoutsImage} 
                />
              </Grid>
              <Grid item xs={mobileSize} md={tabletSize} lg={desktopSize}>
                <TextImageLink
                title='Store'
                subTitle='Your favourite items at sale prices..'
                linkUrl='/store'
                backgroundImage={storeImage} 
                />
              </Grid>
              {/* <Grid item xs={mobileSize} md={tabletSize} lg={desktopSize}>
                <TextImageCarousel
                  title='Store'
                  subTitle='Your favourite items at sale prices.'
                  images={[
                    { backgroundImage: blackTeeImage, linkUrl: 'https://www.etsy.com/uk/listing/1719214882/gym-progress-motivational-t-shirt-muscle?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=gym+progress&ref=sr_gallery-1-5&content_source=bef0f710b8e9c9966e00768b5141ce0f307f063e%253A1719214882&organic_search_click=1' },
                    { backgroundImage: blueTeeImage, linkUrl: 'https://www.etsy.com/uk/listing/1719214882/gym-progress-motivational-t-shirt-muscle?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=gym+progress&ref=sr_gallery-1-5&content_source=bef0f710b8e9c9966e00768b5141ce0f307f063e%253A1719214882&organic_search_click=1' },
                    { backgroundImage: greyTeeImage, linkUrl: 'https://www.etsy.com/uk/listing/1719214882/gym-progress-motivational-t-shirt-muscle?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=gym+progress&ref=sr_gallery-1-5&content_source=bef0f710b8e9c9966e00768b5141ce0f307f063e%253A1719214882&organic_search_click=1' },
                    { backgroundImage: redTeeImage, linkUrl: 'https://www.etsy.com/uk/listing/1719214882/gym-progress-motivational-t-shirt-muscle?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=gym+progress&ref=sr_gallery-1-5&content_source=bef0f710b8e9c9966e00768b5141ce0f307f063e%253A1719214882&organic_search_click=1' }
                  ]}
                />
              </Grid> */}
      <Grid item xs={mobileSize} md={tabletSize} lg={desktopSize}>
        <TextImageLink
          title='Find a Personal Trainer'
          subTitle='Locate the best trainers in your area.'
          linkUrl='/find-a-pt'
          backgroundImage={findPTImage} // New image for PT section
        />
      </Grid>

              {/* <Grid item xs={mobileSize} md={tabletSize} lg={desktopSize}>
              <TextImageLink
                title='Gym News'
                subTitle='Keep upto date with all things fitness.'
                linkUrl='/workouts'
                backgroundImage={gymNewsImage} 
                />
              </Grid> */}
          </Grid>
        </PageContainer>
    </>
  )
}