import React from 'react'
import Container from '@mui/material/Container';
import  ExercisesList from '../components/ExercisesList';
import { Exercise } from '../components/ExercisesList';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import  PageContainer from '../components/PageContainer';


export default function ExercisesPage() {
  const exercisesJSON: Exercise[] = require('../exercises.json');
  return (
    <>
      <PageContainer>
        <Typography variant="h1">
          All Exercises
        </Typography>
        <ExercisesList  exerciseData={exercisesJSON}/>
      </PageContainer>
    </>
  )
}

