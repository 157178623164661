import React from 'react';
import { Typography, Box } from '@mui/material';

interface ExerciseHistoryProps {
  progressData: { [key: number]: ProgressEntry[] };
  exerciseId: number;
}

interface ProgressEntry {
  date: string;
  set: number;
  reps: string;
  weight: string;
}

const ExerciseHistory: React.FC<ExerciseHistoryProps> = ({ progressData, exerciseId }) => {
    const exerciseHistory = progressData[exerciseId];
  
    if (!exerciseHistory || exerciseHistory.length === 0) {
      return <Typography variant="body1">No history available for this exercise.</Typography>;
    }
  
    // Group entries by date
    const historyByDate = exerciseHistory.reduce((acc: { [date: string]: ProgressEntry[] }, entry: ProgressEntry) => {
      if (!acc[entry.date]) {
        acc[entry.date] = [];
      }
      acc[entry.date].push(entry);
      return acc;
    }, {});
  
    // Sort dates in descending order (most recent first)
    const sortedDates = Object.keys(historyByDate).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
  
    return (
      <Box>
        <Typography variant="h6">Exercise History</Typography>
        <Box sx={{ maxHeight: '200px', overflowY: 'auto' }}> {/* Adjust maxHeight as needed */}
          {sortedDates.map(date => (
            <Box key={date} mt={2}>
              <Typography variant="body2" fontWeight="bold">{new Date(date).toLocaleDateString()}</Typography>
              <ul>
                {historyByDate[date].map((entry, index) => (
                  <li key={index}>
                    Set {entry.set}: {entry.reps} reps @ {entry.weight} lbs
                  </li>
                ))}
              </ul>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  

export default ExerciseHistory;
