import React from 'react';
import { Grid, TextField } from '@mui/material';

interface ExerciseInputProps {
  exerciseId: number;
  setIndex: number;
  currentDate: string;
  progressData: any;
  previousValuesForExercise: any;
  handleInputChange: (exerciseId: number, setIndex: number, field: 'reps' | 'weight', value: string) => void;
}

const ExerciseInput: React.FC<ExerciseInputProps> = ({
    exerciseId,
    setIndex,
    currentDate,
    progressData,
    previousValuesForExercise,
    handleInputChange,
  }) => {
  
    const getRepsValue = () => {
      const entry = progressData[exerciseId]?.find(
        (entry: any) => entry.date === currentDate && entry.set === setIndex + 1
      );
      return entry?.reps || previousValuesForExercise?.[setIndex]?.reps || '';
    };
  
    const getWeightValue = () => {
      const entry = progressData[exerciseId]?.find(
        (entry: any) => entry.date === currentDate && entry.set === setIndex + 1
      );
      return entry?.weight || previousValuesForExercise?.[setIndex]?.weight || '';
    };
  
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={`Set ${setIndex + 1} Reps`}
            value={getRepsValue()}
            onChange={(e) => handleInputChange(exerciseId, setIndex, 'reps', e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={`Set ${setIndex + 1} Weight`}
            value={getWeightValue()}
            onChange={(e) => handleInputChange(exerciseId, setIndex, 'weight', e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };
  

export default ExerciseInput;
