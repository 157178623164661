import React, { Key } from 'react'
import { VictoryChart, VictoryArea, VictoryLine, VictoryAxis, VictoryLabel } from 'victory';
import Grid from '@mui/material/Grid'
import LineGraph from './graphs/LineGraph';

const bodyWeightData = [
    {date: "Jan 2023", weight: 180},
    {date: "Feb 2023", weight: 185},
    {date: "Mar 2023", weight: 210},
    {date: "Apr 2023", weight: 215},
    {date: "May 2023", weight: 230},
    {date: "Jun 2023", weight: 245},
  ];
  
  const dailyCaloriesData = [
    {date: "Jan 2023", calories: 3000},
    {date: "Feb 2023", calories: 3500},
    {date: "Mar 2023", calories: 2800},
    {date: "Apr 2023", calories: 3300}
  ];
  
  const restingHeartRateData = [
    {date: "Jan 2023", bpm: 3000},
    {date: "Feb 2023", bpm: 3500},
    {date: "Mar 2023", bpm: 2800},
    {date: "Apr 2023", bpm: 3300}
  ];
  
  const runDistanceData = [
    {date:  "Jan 2023", distance: 5},
    {date:  "Feb 2023", distance: 10},
    {date:  "Apr 2023", distance: 12},
    {date:  "May 2023", distance: 20}
  ];
  
  const maxSquatData = [
    {date:  "Jan 2023", weight: 100},
    {date:  "Feb 2023", weight: 140},
    {date:  "Apr 2023", weight: 180},
    {date:  "May 2023", weight: 210}
  ];
  
  const maxDeadliftData = [
    {date:  "Jan 2023", weight: 100},
    {date:  "Feb 2023", weight: 140},
    {date:  "Apr 2023", weight: 180},
    {date:  "May 2023", weight: 210}
  ];
  
  const maxBenchPressData = [
    {date:  "Jan 2023", weight: 100},
    {date:  "Feb 2023", weight: 140},
    {date:  "Apr 2023", weight: 180},
    {date:  "May 2023", weight: 210}
  ];
  
  const maxShoulderPressData = [
    {date:  "Jan 2023", weight: 100},
    {date:  "Feb 2023", weight: 140},
    {date:  "Apr 2023", weight: 180},
    {date:  "May 2023", weight: 210}
  ];

  
export default function GraphProgress() {
  return (
    <Grid container marginBottom={6}>
      <Grid xs={6} md={2}>
        <LineGraph headerText='Body Weight' XAxis='date' XAxisLabel='Date' YAxis='weight' YAxisLabel='Weight (lbs)' data={bodyWeightData}/>
      </Grid>
      <Grid xs={6} md={2}>
        <LineGraph headerText='Max Squat' XAxis='date' XAxisLabel='Date' YAxis='weight' YAxisLabel='Weight (kgs)' data={maxSquatData}/>
      </Grid>
      <Grid xs={6} md={2}>
        <LineGraph headerText='Max Deadlift' XAxis='date' XAxisLabel='Date' YAxis='weight' YAxisLabel='Weight (kgs)' data={maxDeadliftData}/>
      </Grid>
      <Grid xs={6} md={2}>
        <LineGraph headerText='Max Bench Press' XAxis='date' XAxisLabel='Date' YAxis='weight' YAxisLabel='Weight (kgs)' data={maxBenchPressData}/>
      </Grid>
      <Grid xs={6} md={2}>
        <LineGraph headerText='Max Shoulder Press' XAxis='date' XAxisLabel='Date' YAxis='weight' YAxisLabel='Weight (kgs)' data={maxShoulderPressData}/>
      </Grid>
      <Grid xs={6} md={2}>
        <LineGraph headerText='Daily Calories' XAxis='date' XAxisLabel='Date' YAxis='calories' YAxisLabel='Calories (kcals)' data={dailyCaloriesData}/>
      </Grid>
      {/* <Grid xs={6} md={2}>
        <LineGraph headerText='Resting Heart Rate' XAxis='date' XAxisLabel='Date' YAxis='bpm' YAxisLabel='BPM (bpm)' data={restingHeartRateData}/>
      </Grid>
      <Grid xs={6} md={2}>
        <LineGraph headerText='Run Distance' XAxis='date' XAxisLabel='Date' YAxis='distance' YAxisLabel='Distance (km)' data={runDistanceData}/>
      </Grid> */}
    </Grid>
  )
}
