import Container from '@mui/material/Container';
import React from 'react'

interface TextImageBackgroundProps {
    backgroundImage: string;
    title: string;
    subTitle: string;
  }

export default function TextImageBackground({ backgroundImage, title, subTitle }: TextImageBackgroundProps) {
    const outerContainerStyle: React.CSSProperties = {
        backgroundImage: `url(${backgroundImage})`,
        position: "relative",
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        textAlign: "left",
        minHeight: 250, /* Set an appropriate minimum height */
      };

      const innerContainerStyle: React.CSSProperties = {
        //backgroundColor: "rgba(0, 0, 0, 0.6)", /* Darken the background image */
        minHeight: 250, /* Set an appropriate minimum height */
      };

      const textContainerStyle: React.CSSProperties = {
        paddingTop: 20,
        paddingBottom: 20,
      };

      
    
      return (
        <div className="text-image-background" style={outerContainerStyle}>
          <div style={innerContainerStyle}>
          <Container maxWidth="xl">
          <div className="text-content" style={textContainerStyle}>
            <h1>{title}</h1>
            <p>{subTitle}</p>
          </div>
          </Container>
          </div>

        </div>
      );

  };