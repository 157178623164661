import React, { useState } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import ExerciseCard from './ExerciseCard';
import { Exercise } from './WorkoutsList';
import { styled } from '@mui/system';

const WorkoutBox = styled(Box)`
    border-top: 1px solid black;
    padding-top: 10px;
    // border-radius: 6px;
    // background-color: #f5f5f5;
    margin-bottom: 30px;
`;

const WorkoutHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const maxNumberOfExercisesShown = 6;

interface WeeklyWorkoutsProps {
  filteredExercises: Exercise[];
  selectedDaysPerWeek: number;
}

const WeeklyWorkouts: React.FC<WeeklyWorkoutsProps> = ({ filteredExercises, selectedDaysPerWeek }) => {
  // Calculate the number of exercises per workout
  const exercisesPerWorkout = Math.ceil(filteredExercises.length / selectedDaysPerWeek);

  // Split filtered exercises into weekly workouts
  const weeklyWorkouts: Exercise[][] = [];
  for (let i = 0; i < selectedDaysPerWeek; i++) {
    const startIdx = i * exercisesPerWorkout;
    const endIdx = (i + 1) * exercisesPerWorkout;
    const workoutExercises = filteredExercises.slice(startIdx, endIdx);
    weeklyWorkouts.push(workoutExercises);
  }

  // State to control whether to show all exercises
  const [showAllExercises, setShowAllExercises] = useState(false);

  const toggleShowAll = () => {
    setShowAllExercises(!showAllExercises);
  };

  return (
    <div>
      {weeklyWorkouts.map((workout, index) => (
        <WorkoutBox key={index}>
            <WorkoutHeader>
                <Typography variant="h3" gutterBottom>
                    Workout {String.fromCharCode(65 + index)}
                </Typography>
                <Button variant='contained' onClick={toggleShowAll}>{showAllExercises ? 'Show Limited' : 'Show All'}</Button>
            </WorkoutHeader>
          
          <Grid container spacing={2}>
            {showAllExercises
              ? workout.map((exercise) => (
                  <Grid item xs={12} sm={6} md={4} key={exercise.id}>
                    <ExerciseCard key={exercise.id} exercise={exercise} />
                  </Grid>
                ))
              : workout.slice(0, maxNumberOfExercisesShown).map((exercise) => (
                  <Grid item xs={12} sm={6} md={4} key={exercise.id}>
                    <ExerciseCard key={exercise.id} exercise={exercise} />
                  </Grid>
                ))}
          </Grid>
        </WorkoutBox>
      ))}
    </div>
  );
};

export default WeeklyWorkouts;