import React, { useState } from 'react';
import { Typography, Grid, TextField, Button, Box, Container, MenuItem, Select, FormControl, InputLabel, LinearProgress } from '@mui/material';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel } from 'victory';
import Cookies from 'js-cookie';
import exercisesData from '../exercises.json';
import SampleBeforeImage from '../assets/images/sample_before.jpg';
import SampleAfterImage from '../assets/images/sample_after.jpg';
import PageContainer from '../components/PageContainer';
import styled from '@emotion/styled';
import BMIComponent from '../components/BMIComponent';

const ExerciseTitle = styled(Typography)`
  font-size: 1rem;
`;

interface Exercise {
  id: number;
  title: string;
}

interface ProgressEntry {
  date: string;
  set: number;
  reps: string;
  weight: string;
}

const MyProgressPage: React.FC = () => {
  const [userInfo, setUserInfo] = React.useState({ name: '', height: '', weight: '', BMI: '' });
  const [unitInfo, setUnitInfo] = React.useState({ heightUnit: 'cm', weightUnit: 'kg' });
  const [progressData, setProgressData] = React.useState<{ [key: number]: ProgressEntry[] }>({});
  const [sex, setSex] = useState<string>('female');  // Default value
  const [age, setAge] = useState<number>(26);
  const [height, setHeight] = useState<number>(163);  // Assume height is already known
  const [weight, setWeight] = useState<number>(60);   // Assume weight is known
  const bmi = weight / ((height / 100) * (height / 100));  // Calculate BMI


  React.useEffect(() => {
    const savedUserInfo = Cookies.get('userInfo');
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }

    const savedUnitInfo = Cookies.get('unitInfo');
    if (savedUnitInfo) {
      setUnitInfo(JSON.parse(savedUnitInfo));
    }

    const loadProgress = () => {
      const data: { [key: number]: ProgressEntry[] } = {};
      exercisesData.forEach(exercise => {
        const savedData = Cookies.get(`exercise_${exercise.id}`);
        if (savedData) {
          data[exercise.id] = JSON.parse(savedData);
        }
      });
      setProgressData(data);
    };

    loadProgress();
  }, []);

  const calculateBMI = (weight: number, height: number): number => {
    const heightInMeters = unitInfo.heightUnit === 'cm' ? height / 100 : height * 0.3048; // Convert to meters
    const weightInKg = unitInfo.weightUnit === 'kg' ? weight : unitInfo.weightUnit === 'pounds' ? weight * 0.453592 : weight * 6.35029; // Convert to kg
    return weightInKg / (heightInMeters * heightInMeters);
  };

  React.useEffect(() => {
    if (userInfo.height && userInfo.weight) {
      const bmi = calculateBMI(parseFloat(userInfo.weight), parseFloat(userInfo.height));
      setUserInfo(prevState => ({
        ...prevState,
        BMI: bmi.toFixed(1)
      }));
    }
  }, [userInfo.height, userInfo.weight, unitInfo.heightUnit, unitInfo.weightUnit]);

  const handleInputChange = (field: string, value: string) => {
    setUserInfo({
      ...userInfo,
      [field]: value
    });
  };

  const handleUnitChange = (field: string, value: string) => {
    setUnitInfo({
      ...unitInfo,
      [field]: value
    });
  };

  const handleSaveUserInfo = () => {
    Cookies.set('userInfo', JSON.stringify(userInfo), { expires: 30 });
    Cookies.set('unitInfo', JSON.stringify(unitInfo), { expires: 30 });
  };

  const BMIScale = ({ bmi }: { bmi: number }) => {
    return (
      <>
      {/* <Box mt={4}>
        <Typography variant="h6">BMI: {bmi}</Typography>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={(bmi / 40) * 100} style={{ height: 10, backgroundColor: '#e0e0e0' }} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{bmi}</Typography>
          </Box>
        </Box>
        <Box mt={1} display="flex" justifyContent="space-between">
          <Typography variant="body2" color="blue">Underweight</Typography>
          <Typography variant="body2" color="green">Normal</Typography>
          <Typography variant="body2" color="yellow">Overweight</Typography>
          <Typography variant="body2" color="red">Obese</Typography>
        </Box>
      </Box> */}
      <BMIComponent bmi={bmi} sex={sex} age={age} height={height} weight={weight} />
      </>
    );
  };

  return (
    <PageContainer>
      <div>
        <Typography variant="h2">My Progress</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
              <Grid item xs={6}>
                <Typography variant="h6">Before - Front</Typography>
                <img
                  src={SampleBeforeImage} // Placeholder path, replace with actual image path
                  alt="Before - Front"
                  style={{ width: '100%', borderRadius: '8px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Before - Back</Typography>
                <img
                  src={SampleBeforeImage} // Placeholder path, replace with actual image path
                  alt="Before - Back"
                  style={{ width: '100%', borderRadius: '8px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Now - Front</Typography>
                <img
                  src={SampleAfterImage} // Placeholder path, replace with actual image path
                  alt="Now - Front"
                  style={{ width: '100%', borderRadius: '8px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Now - Back</Typography>
                <img
                  src={SampleAfterImage} // Placeholder path, replace with actual image path
                  alt="Now - Back"
                  style={{ width: '100%', borderRadius: '8px' }}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Name"
              value={userInfo.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              fullWidth
              style={{ marginBottom: '16px' }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Height"
                  value={userInfo.height}
                  onChange={(e) => handleInputChange('height', e.target.value)}
                  fullWidth
                  style={{ marginBottom: '16px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth style={{ marginBottom: '16px' }}>
                  <InputLabel>Unit</InputLabel>
                  <Select
                    value={unitInfo.heightUnit}
                    onChange={(e) => handleUnitChange('heightUnit', e.target.value as string)}
                  >
                    <MenuItem value="cm">cm</MenuItem>
                    <MenuItem value="feet">feet</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Weight"
                  value={userInfo.weight}
                  onChange={(e) => handleInputChange('weight', e.target.value)}
                  fullWidth
                  style={{ marginBottom: '16px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth style={{ marginBottom: '16px' }}>
                  <InputLabel>Unit</InputLabel>
                  <Select
                    value={unitInfo.weightUnit}
                    onChange={(e) => handleUnitChange('weightUnit', e.target.value as string)}
                  >
                    <MenuItem value="kg">kg</MenuItem>
                    <MenuItem value="pounds">pounds</MenuItem>
                    <MenuItem value="stone">stone</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div>
                <label>
                    <input
                        type="radio"
                        value="male"
                        checked={sex === 'male'}
                        onChange={() => setSex('male')}
                    />
                    Male
                </label>
                <label>
                    <input
                        type="radio"
                        value="female"
                        checked={sex === 'female'}
                        onChange={() => setSex('female')}
                    />
                    Female
                </label>
                <label>
                    <input
                        type="radio"
                        value="prefer not to say"
                        checked={sex === 'prefer not to say'}
                        onChange={() => setSex('prefer not to say')}
                    />
                    Prefer not to say
                </label>
            </div>
            <BMIScale bmi={parseFloat(userInfo.BMI)} />

            <Button variant="contained" onClick={handleSaveUserInfo} style={{ marginTop: '16px' }}>
              Save User Info
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '32px' }}>
          {exercisesData.map((exercise) => (
            <Grid item xs={6} md={3} key={exercise.id}>
              <ExerciseTitle variant="h4">{exercise.title}</ExerciseTitle>
              <VictoryChart domainPadding={20}>
                <VictoryAxis
                  tickFormat={(x) => new Date(x).toLocaleDateString()}
                  label="Date"
                />
                <VictoryAxis dependentAxis label="Weight" />
                <VictoryBar
                  data={progressData[exercise.id] || []}
                  x="date"
                  y="weight"
                  labels={({ datum }) => datum.reps}
                  labelComponent={<VictoryLabel dy={30} />}
                />
              </VictoryChart>
            </Grid>
          ))}
        </Grid>
      </div>
    </PageContainer>
  );
};

export default MyProgressPage;
