import React from 'react';
import ExercisesFilter from './ExercisesFilter'; // Ensure correct import path
import ExerciseCard from './ExerciseCard'; // Ensure correct import path
import { Grid } from '@mui/material';
import DaysPerWeekSelector from './DaysPerWeekSelector';
interface ExerciseListProps {
  exerciseData: Exercise[];
}

export interface Exercise {
    id: number;
    title: string;
    description: string;
    videoUrl: string;
    image: string;
    isGymOnly: boolean;
    isHomeOnly: boolean;
    isBuildingMuscle: boolean;
    isCardio: boolean;
    isBeginnerSafe: boolean;
    isIntermediatePro: boolean;
    musclesUsed: string[];
    // Add other properties as needed
  }

interface WorkoutFilters {
  isGymOnly: boolean;
  isHomeOnly: boolean;
  isBuildingMuscle: boolean;
  isCardio: boolean;
  isBeginnerSafe: boolean;
  isIntermediatePro: boolean;
}

const ExercisesList: React.FC<ExerciseListProps> = ({ exerciseData }) => {
  const [filters, setFilters] = React.useState<WorkoutFilters>({
    isGymOnly: false,
    isHomeOnly: false,
    isBuildingMuscle: false,
    isCardio: false,
    isBeginnerSafe: false,
    isIntermediatePro: false,
  });

  const handleFilterChange = (updatedFilters: WorkoutFilters): void => {
    setFilters(updatedFilters);
  };

  const filteredWorkouts = exerciseData.filter((exercise) => {
    // Apply your filtering logic based on the filters state
    // Example: Check if the workout is for the gym only and bodybuilding
    return (
      (!filters.isGymOnly || exercise.isGymOnly) &&
      (!filters.isHomeOnly || exercise.isHomeOnly) &&
      (!filters.isBuildingMuscle || exercise.isBuildingMuscle) &&
      (!filters.isCardio || exercise.isCardio) &&
      (!filters.isBeginnerSafe || exercise.isBeginnerSafe) &&
      (!filters.isIntermediatePro || exercise.isIntermediatePro)
      // Add more conditions based on your filtering requirements
    );
  });


  return (
    <div>
      <ExercisesFilter filters={filters} onFilterChange={handleFilterChange} />
      <Grid container spacing={2}>
        {filteredWorkouts.map((exercise) => (
          <Grid item xs={12} sm={6} md={3} key={exercise.id}>
            <ExerciseCard key={exercise.id} exercise={exercise} showFilterIcons={true} />
          </Grid>
        ))}
      </Grid>
      {/* Other components for displaying filtered workouts */}
    </div>
  );
};

export default ExercisesList;