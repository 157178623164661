import React, {useState} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

// ImageGallery.tsx
import { Grid, Paper, Typography, TextField, FormControl, InputLabel, MenuItem, Select, Stack, Box } from '@mui/material';

export interface ImageData {
  src: string;
  date: string;
  weight: string;
  pose: "Front" | "Back" | "Side";
}

interface ImageGalleryProps {
  images: ImageData[];
}

export default function ImageListing({images}: ImageGalleryProps) {

  const [filterDate, setFilterDate] = useState('');
  const [filterWeight, setFilterWeight] = useState('');
  const [filterPose, setFilterPose] = useState('');

  const filteredImages = images.filter((image) => {
    const dateMatches = image.date.includes(filterDate);
    const weightMatches = image.weight.includes(filterWeight);
    const poseMatches = !filterPose || image.pose === filterPose;

    return dateMatches && weightMatches && poseMatches;
  });

  return (
    <>        
        <Grid container spacing={4} mb={3}>
          <Grid item xs={4}>
            <FormControl fullWidth>
            <InputLabel>Filter by Pose</InputLabel>
            <Select
              value={filterPose}
              onChange={(e) => setFilterPose(e.target.value as "Front" | "Back" | "Side")}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="Front">Front</MenuItem>
              <MenuItem value="Back">Back</MenuItem>
              <MenuItem value="Side">Side</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
            label="Filter by Date"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
            fullWidth
          />
          </Grid>
          <Grid item xs={4}>
                <TextField
            label="Filter by Weight"
            value={filterWeight}
            onChange={(e) => setFilterWeight(e.target.value)}
            fullWidth
          />
          </Grid>
          </Grid>

        <Grid container spacing={2}>

            {filteredImages.map((image, index) => (
            <Grid item key={index} xs={2}>
                <Paper>
                    <img src={image.src} alt={`Image ${index}`} width="100%" />
                    <Box p={2}>
                        <Typography variant="body1">
                          {`Date: ${image.date}`}
                          </Typography>
                        <Typography variant="body1">
                          {`Weight: ${image.weight}`}
                          </Typography>
                        <Typography variant="body1">
                          {`Pose: ${image.pose}`}
                          </Typography>
                    </Box>
                </Paper>
            </Grid>
            ))}
    </Grid>
  </>
  );
}

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
    cols: 2,
  },
];
