import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import TermsAndConditions from './TermsAndConditions';

interface Props {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
}

const TermsAndConditionsPopup: React.FC<Props> = ({ open, onClose, onAccept }) => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    onAccept();
  };

  return (
    <Dialog open={open}  onClose={() => {}} maxWidth="md" fullWidth>
      <DialogTitle>Terms and Conditions</DialogTitle>
      <DialogContent dividers>
        <TermsAndConditions />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} color="primary">Accept</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsPopup;