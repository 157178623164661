import React from 'react';

const TermsAndConditions: React.FC = () => {
  return (
    <div>
      {/* <h2>Terms and Conditions</h2> */}
      <p>
        By accessing and using this website, you agree to the following terms and conditions:
      </p>
      <p>
        This website, "Gym Progress," provides information and resources related to health, fitness, workouts, exercises,
        affiliated links to products, and news articles about fitness. While we strive to provide accurate and up-to-date
        information, we make no representations or warranties of any kind, express or implied, about the completeness,
        accuracy, reliability, suitability, or availability with respect to the website or the information, products,
        services, or related graphics contained on the website for any purpose. Any reliance you place on such information
        is therefore strictly at your own risk.
      </p>
      <p>
        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss
        or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection
        with, the use of this website.
      </p>
      <p>
        Through this website, you are able to link to other websites that are not under the control of "Gym Progress."
        We have no control over the nature, content, and availability of those sites. The inclusion of any links does not
        necessarily imply a recommendation or endorsement of the views expressed within them.
      </p>
      <p>
        Furthermore, "Gym Progress" may collect personal data from users for the purpose of improving user experience and
        providing personalized content. We are committed to protecting your privacy and will handle your personal information
        in accordance with our Privacy Policy.
      </p>
      <p>
        By using this website, you acknowledge and agree that "Gym Progress" and its affiliates, partners, and contributors
        shall not be held responsible for any direct, indirect, incidental, special, consequential, or punitive damages arising
        out of your access to or use of the website.
      </p>
      <p>
        You agree to indemnify, defend, and hold harmless "Gym Progress" and its affiliates, partners, and contributors from
        and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys'
        fees) arising from your use of the website or any violation of these terms and conditions.
      </p>
      <p>
        These terms and conditions constitute the entire agreement between you and "Gym Progress" regarding your use of the
        website, superseding any prior agreements between you and us. If any provision of these terms and conditions is found
        to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
      </p>
      <p>
        "Gym Progress" reserves the right to modify, amend, or update these terms and conditions at any time without prior
        notice. Your continued use of the website following any changes constitutes acceptance of those changes.
      </p>
      <p>
        If you do not agree to these terms and conditions, please refrain from using this website.
      </p>
      <p>
        Thank you for your understanding and cooperation. If you have any questions or concerns about these terms and conditions,
        please contact us.
      </p>
    </div>
  );
};

export default TermsAndConditions;