// create react functional compoinent for Header Desktop 
import { Box, Typography } from '@mui/material';
import React from 'react';
import { NavButton } from './Header';
import { Link, useLocation  } from 'react-router-dom';

interface HeaderDesktopProps {
        pages: {
                label: string, 
                url: string
        }[];
        handleCloseNavMenu: () => void;
}

const HeaderDesktop: React.FC<HeaderDesktopProps> = ({pages, handleCloseNavMenu}) => {
    const location = useLocation();
        return (
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                    <Link to={page.url} key={page.url}>
                        <NavButton
                            onClick={handleCloseNavMenu}
                            className={location.pathname === page.url ? 'active' : ''}
                        >
                            <Box key={page.url}  >
                                    <Typography textAlign="center" color="black">{page.label}</Typography>
                            </Box>
                        </NavButton>
                    </Link>
                ))}
            </Box>
        );
};

export default HeaderDesktop;