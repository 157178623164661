// SortableItem.tsx
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Paper } from '@mui/material';

interface SortableItemProps {
  id: string;
  title: string;
}

export const SortableItem: React.FC<SortableItemProps> = ({ id, title }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Paper
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      elevation={3}
      sx={{ margin: '8px', padding: '16px', backgroundColor: '#f0f0f0' }}
    >
      {title}
    </Paper>
  );
};
