import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faHeartPulse, faHouseChimney, faMedal, faRankingStar, faScaleBalanced, faWeightHanging } from '@fortawesome/free-solid-svg-icons';
import { Exercise } from './WorkoutsList'; // Adjust the import path
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import staticImage from '../assets/images/exercises/pull_ups.jpg';

const defaultImagePath = require('../assets/images/exercises/no_image_available.jpg'); // Full path to your default image



const ExerciseBox = styled(Box)`
  border: 1px solid #ccc;
  padding: 10px;
  // margin-bottom: 20px;
  border-radius: 6px;
  background-color: #f5f5f5;
`;

const ExerciseImg = styled("img")`
  width: 100%;
`;

const IconBox = styled(Box)`
  display: flex;
  // margin-bottom: 8px;
  justify-content: flex-end;
  & svg {
    margin-right: 8px;
  }
`;


interface WorkoutCardProps {
  exercise: Exercise;
  showFilterIcons?: boolean;
}

const ExerciseCard: React.FC<WorkoutCardProps> = ({ exercise, showFilterIcons=true }) => {
  // Dynamic Image Import (with fallback)
  let imagePath = null;
  try {
    imagePath = require(`../assets/images/exercises/${exercise.image}`);
  } catch (error) {
      console.error('Error loading image:', error); // Log the error
      imagePath = defaultImagePath; 
  }

  console.log('Image Path:', `../assets/images/exercises/${exercise.image}`);

  return (
    <ExerciseBox>
      <Typography variant='h3' component="h2">{exercise.title}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ExerciseImg src={imagePath} alt={exercise.title} />
        </Grid>
        <Grid item xs={6}>
          <Typography>{exercise.description}</Typography>
        </Grid>
      </Grid>
      {/* <p>ID: {exercise.id}</p> */}
      { showFilterIcons && (
        <IconBox>
        {exercise.isGymOnly && <FontAwesomeIcon icon={faDumbbell} title="Gym Workout" />}
        {exercise.isHomeOnly && <FontAwesomeIcon icon={faHouseChimney} title="Home Workout" />}
        {exercise.isBuildingMuscle && <FontAwesomeIcon icon={faWeightHanging} title="Building Muscle" />}
        {exercise.isCardio && <FontAwesomeIcon icon={faHeartPulse} title="Cardio Workout" />}
        {exercise.isBeginnerSafe && <FontAwesomeIcon icon={faRankingStar} title="Beginner Safe" />}
        {exercise.isIntermediatePro && <FontAwesomeIcon icon={faMedal} title="Intermediate/Pro Level" />}
      </IconBox>

      )}

    </ExerciseBox>
  );
};

export default ExerciseCard;