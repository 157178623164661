import React from 'react'
import TextImageBackground from '../components/TextImageBackground';
import TextParagraph from '../components/TextParagraph';
import workoutImage from '../assets/images/workouts.jpg';
import personalTrainingImage from '../assets/images/personal_training.jpg';
import myProgressImage from '../assets/images/my_progress.png';
import socialImage from '../assets/images/social.jpg';
import Container from '@mui/material/Container';
import TextImageSideBySide from '../components/TextImageSideBySide';
import ImageListing from '../components/ImageListing';
import GraphProgress from '../components/GraphProgress';

export interface ImageData {
  src: string;
  date: string;
  weight: string;
  pose: "Front" | "Back" | "Side";
}

interface ImageGalleryProps {
  images: ImageData[];
}

const images: ImageData[] = [
  { src: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e', date: '2023-10-15', weight: '200 lbs', pose: 'Front' },
  { src: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d', date: '2023-10-15', weight: '200 lbs', pose: 'Back' },
  { src: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45', date: '2023-10-15', weight: '200 lbs', pose: 'Side' },

  { src: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c', date: '2023-10-08', weight: '200 lbs', pose: 'Front' },
  { src: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8', date: '2023-10-08', weight: '200 lbs', pose: 'Back' },
  { src: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62', date: '2023-10-08', weight: '200 lbs', pose: 'Side' },

  { src: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6', date: '2023-10-01', weight: '190 lbs', pose: 'Front' },
  { src: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f', date: '2023-10-01', weight: '190 lbs', pose: 'Back' },
  { src: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25', date: '2023-10-01', weight: '190 lbs', pose: 'Side' },


  // Add more image objects with date and weight properties
];

export default function ProgressPage() {
  return (
    <>
        <Container maxWidth="xl">
        <TextParagraph
            title="Progress Page!"
            paragraph="At Gym Progress, we believe that physical fitness isn't just about building a better body; it's about nurturing a healthier mind too. Our platform is designed to help you embark on a transformative fitness journey that not only strengthens your body but also empowers your mind."
          />
          <GraphProgress/>
         

          <ImageListing images={images}/>
        </Container>
    </>
  )
}
