import React from 'react'
import TextParagraph from '../components/TextParagraph';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import WorkoutsList from '../components/WorkoutsList';
import { Exercise } from '../components/WorkoutsList';
import { Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import PageContainer from '../components/PageContainer';

export default function PopularWorkoutsPage() {
  const exercisesJSON: Exercise[] = require('../exercises.json');
  const navigate = useNavigate();

  return (
    <>
      <PageContainer>
        <Typography variant="h1">
          Popular Workouts
        </Typography>
         {/* <Button variant="contained" color="primary" onClick={() => navigate('/workouts/custom-workout')}>
          Create Custom Workout
        </Button> */}
        <WorkoutsList exerciseData={exercisesJSON} /> 
      </PageContainer>
    </>
  )
}

