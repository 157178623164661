import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { LatLngTuple } from 'leaflet';
import { Box, Container, TextField, Grid, Card, CardContent, Typography, CardMedia, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent } from '@mui/material';
import trainersData from '../pts.json'; // Import the JSON file
import trainerLogo from '../assets/svgs/Trainer_Icon.svg';
import countries from '../countries.json'; // Assume this JSON file contains country data with lat/lng coordinates
import { Link } from 'react-router-dom';

// Custom marker icon
const trainerIcon = new L.Icon({
  iconUrl: trainerLogo,
  iconSize: [32, 32]
});

// Define a zoom level variable for easier adjustment
const countryZoomLevel = 5;

export default function FindPTPage() {
  const [filteredTrainers, setFilteredTrainers] = useState(trainersData);
  const [country, setCountry] = useState('');
  const [filters, setFilters] = useState({
    name: '',
    expertise: '',
    qualification: ''
  });

  const mapRef = useRef<L.Map>(null);

  useEffect(() => {
    filterTrainers();
  }, [filters]);

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);

    const countryData = countries.find(c => c.name === selectedCountry);
    if (countryData && mapRef.current) {
      mapRef.current.setView([countryData.lat, countryData.lng], countryZoomLevel);
    }
  };

  const filterTrainers = () => {
    const filtered = trainersData.filter(trainer =>
      (Object.keys(filters) as (keyof typeof filters)[]).every(key => 
        (trainer as any)[key].toLowerCase().includes((filters as any)[key].toLowerCase())
      )
    );

    setFilteredTrainers(filtered);
  };

  return (
    <Container maxWidth="xl">
      <h1>Find a Personal Trainer</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <MapContainer center={[54.0, -2.0]} zoom={countryZoomLevel} style={{ height: '500px' }} ref={mapRef}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; OpenStreetMap contributors"
            />
            {filteredTrainers.map((trainer, index) => (
              <Marker key={index} position={trainer.location as LatLngTuple} icon={trainerIcon}>
                <Popup>
                  <div>
                    <img
                      src={require(`../assets/images/pts/${trainer.image}`)}
                      alt={trainer.name}
                      style={{ width: '100px', height: '100px' }}
                    />
                    <h2>{trainer.name}</h2>
                    <p>Qualification: {trainer.qualification}</p>
                    <p>Experience: {trainer.experience_level}</p>
                    <p>Expertise: {trainer.expertise}</p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Search by Name"
                name="name"
                onChange={handleFilterChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Search by Expertise"
                name="expertise"
                onChange={handleFilterChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Search by Qualification"
                name="qualification"
                onChange={handleFilterChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Select Country</InputLabel>
                <Select
                  value={country}
                  onChange={handleCountryChange}
                  label="Select Country"
                  fullWidth
                >
                  {countries.map((country, index) => (
                    <MenuItem key={index} value={country.name}>{country.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={2}>
            {filteredTrainers.map((trainer, index) => (
              <Grid item xs={12} sm={12} md={6} key={index}>
                <Link to={`/trainer/${trainer.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Card style={{ display: 'flex', marginBottom: '1rem' }}>
                
                  <CardMedia
                    component="img"
                    image={require(`../assets/images/pts/${trainer.image}`)}
                    alt={trainer.name}
                    style={{ width: 151 }}
                  />
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {trainer.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Qualification: {trainer.qualification}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Experience: {trainer.experience_level}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Expertise: {trainer.expertise}</Typography>
                  </CardContent>

                </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
