import React from 'react';
import { Grid, Button } from '@mui/material';
import { styled } from '@mui/system';
import ExerciseInput from './ExerciseInput';
import ExerciseHistory from './ExerciseHistory';
import ExerciseCard from '../components/ExerciseCard';

const TrackExerciseBoxContainer = styled('div')`
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #f5f5f5;
  margin-bottom: 16px;
  padding: 16px;
`;

interface TrackExerciseBoxProps {
  exercise: any;
  progressData: any;
  exerciseId: number;
  previousValuesForExercise: any;
  currentDate: string;
  handleSaveProgress: (exerciseId: number) => void;
  handleInputChange: (exerciseId: number, setIndex: number, field: 'reps' | 'weight', value: string) => void;
}

const TrackExerciseBox: React.FC<TrackExerciseBoxProps> = ({
  exercise,
  progressData,
  exerciseId,
  previousValuesForExercise,
  currentDate,
  handleSaveProgress,
  handleInputChange,
}) => {
  return (
    <TrackExerciseBoxContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ExerciseCard exercise={exercise} showFilterIcons={false} />
        </Grid>
        <Grid item xs={6} md={4}>
          {[0, 1, 2].map((setIndex) => (
            <ExerciseInput
              key={setIndex}
              exerciseId={exerciseId}
              setIndex={setIndex}
              currentDate={currentDate}
              progressData={progressData}
              previousValuesForExercise={previousValuesForExercise}
              handleInputChange={handleInputChange}
            />
          ))}
          <Button variant="contained" onClick={() => handleSaveProgress(exerciseId)}>
            Save Progress
          </Button>
        </Grid>
        <Grid item xs={6} md={4}>
          <ExerciseHistory progressData={progressData} exerciseId={exerciseId} />
        </Grid>
      </Grid>
    </TrackExerciseBoxContainer>
  );
};

export default TrackExerciseBox;
