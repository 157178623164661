import { createTheme } from '@mui/material/styles';

// Define colors from the image
const gymBlue = '#5E8799'; // A color similar to the gym equipment
const gymBlueLight = '#5E8799'; // A color similar to the gym equipment
const gymBlueDark = '#5E8799'; // A color similar to the gym equipment
const gymTan = '#D5A87E'; // A color similar to the wooden floor
const gymTanLight = '#F9CB9A'; // A color similar to the wooden floor
const gymTanDark = '#D39A6F'; // A color similar to the wooden floor
const gymGrey = '#B1B1B1'; // A neutral grey from the image
const gymWhite = '#FFFFFF'; // White color for contrast
const gymBlack = '#000000'; // Black color for text

// Create a custom theme
const theme = createTheme({
  typography: {
    fontFamily: [
      'Playfair Display',
      'serif',
    ].join(','),
    h1: {
      fontSize: '2rem', 
      marginBottom: '1rem',
      color: gymBlack,
    },
    h2: {
      fontSize: '1.75rem',
      marginBottom: '1rem',
      color: gymBlack,
    },
    h3: {
      fontSize: '1.5rem',
      marginBottom: '1rem',
      color: gymBlack,
    },
  },
  palette: {
    primary: {
      main: gymBlue, // Custom primary color from gym equipment
      light: gymBlueLight,
      dark: gymBlueDark,
    },
    secondary: {
      main: gymTan, // A secondary color from the wooden floor
      light: gymTanLight,
      dark: gymTanDark,
    },
    background: {
      default: gymWhite, // Neutral grey background from the image
      paper: gymWhite,   // White background for paper elements
    },
    text: {
      primary: gymBlack, // Black text color
      secondary: gymGrey, // Grey text for less emphasis
    },
    action: {
      active: gymWhite,  // White text on active buttons
      hover: gymTan, // Tan color on button hover
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          // Blue Grey
          // backgroundColor: '#769CB1', // blue/Grey background
          // backgroundColor: '#5E8799', // blue/grey darker background
          // Mid Tan
          
          // backgroundColor: '#F9CB9A', // Light tan background
          // backgroundColor: '#D39A6F', // Mid tan background
          // backgroundColor: '#D5A87E', // Dark tan background

           
          
          backgroundColor: '#000000', // Black background
          color: '#ffffff', // White text
        },
      },
    },
  },
  
});

export default theme;