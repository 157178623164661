import React from 'react';
import Typography from '@mui/material/Typography';
import PageContainer from '../components/PageContainer';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';

interface Product {
    id: number;
    title: string;
    imageUrl: string;
    price: string;
    externalUrl: string;
}

// Example products array
const products: Product[] = [

    {
        id: 5,
        title: 'Myprotein',
        imageUrl: require('../assets/images/affiliate/My_Protein.jpg'),
        price: "World's No. 1 Online Sports Nutrition Brand",
        externalUrl: 'https://www.myprotein.com/',
    },
    {
        id: 6,
        title: 'Nike',
        imageUrl: require('../assets/images/affiliate/Nike.jpg'),
        price: "Just Do It",
        externalUrl: 'https://www.nike.com/',
    },
    {
        id: 7,
        title: 'Body Building',
        imageUrl: require('../assets/images/affiliate/Body_Building.jpg'),
        price: "A community to help you reach your goals!",
        externalUrl: 'https://shop.bodybuilding.com/',
    },
    {
        id: 8,
        title: 'Amazon',
        imageUrl: require('../assets/images/affiliate/Amazon.jpg'),
        price: "Free shipping on millions of items.",
        externalUrl: 'https://www.amazon.com/',
    },
    {
        id: 1,
        title: 'Branded Tee - Black',
        imageUrl: require('../assets/images/etsy/branded_tee_black.jpg'),
        price: '$25.00',
        externalUrl: 'https://www.etsy.com/uk/listing/1719214882/gym-progress-motivational-t-shirt-muscle',
    },
    {
        id: 2,
        title: 'Branded Tee - Blue',
        imageUrl: require('../assets/images/etsy/branded_tee_blue.jpg'),
        price: '$25.00',
        externalUrl: 'https://www.etsy.com/uk/listing/1719214882/gym-progress-motivational-t-shirt-muscle',
    },
    {
        id: 3,
        title: 'Branded Tee - Grey',
        imageUrl: require('../assets/images/etsy/branded_tee_grey.jpg'),
        price: '$25.00',
        externalUrl: 'https://www.etsy.com/uk/listing/1719214882/gym-progress-motivational-t-shirt-muscle',
    },
    {
        id: 4,
        title: 'Branded Tee - Red',
        imageUrl: require('../assets/images/etsy/branded_tee_red.jpg'),
        price: '$25.00',
        externalUrl: 'https://www.etsy.com/uk/listing/1719214882/gym-progress-motivational-t-shirt-muscle',
    },
];

const StorePage: React.FC = () => {
    return (
        <PageContainer>
            <Typography variant="h2" gutterBottom>Store</Typography>
            <Grid container spacing={3}>
                {products.map((product) => (
                    <Grid item xs={12} sm={6} md={4} key={product.id}>
                        <Card>
                            <CardActionArea onClick={() => window.open(product.externalUrl, '_blank')}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={product.imageUrl}
                                    alt={product.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {product.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {product.price}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </PageContainer>
    );
};

export default StorePage;
