import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

const FooterBox = styled(Box)`
    background-color: #f5f5f5;
    border-top: 1px solid black;
    padding: 16px;
    position: fixed; /* Fixed position */
    bottom: 0; /* Stick to the bottom */
    width: 100%; /* Full width */
    display: flex; /* Use flexbox */
    justify-content: space-between; /* Align items at each end of the container */
    align-items: center; /* Vertically center items */
    box-shadow: 0px -2px 4px -1px rgba(0,0,0,0.2), 0px -4px 5px 0px rgba(0,0,0,0.14), 0px -1px 10px 0px rgba(0,0,0,0.12);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const LeftGrid = styled(Grid)`
    display: flex;
    align-items: center;
`;

const Footer = () => {
    return (
        <FooterBox component="footer">
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <LeftGrid item xs={6} md={6}>
                        <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                            {'© '}
                            {new Date().getFullYear() + '. '} 
                            Gym Progress. 
                            All rights reserved.
                        </Typography>
                    </LeftGrid>
                    <Grid item xs={6} md={6} textAlign='end'>
                        <Button component={Link} to="/workouts" variant='contained' color='primary'>
                            Free Workouts
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </FooterBox>
    );
};

export default Footer;
