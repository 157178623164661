import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem'; // Import MenuItem for dropdowns
import WorkoutCard from '../components/WorkoutCard'; // Import WorkoutCard component
import Select from '@mui/material/Select'; // Import Select for dropdowns
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook

interface Exercise {
  id: number;
  title: string;
  description: string;
  videoUrl: string;
  image: string;
  isGymOnly: boolean;
  isHomeOnly: boolean;
  isBuildingMuscle: boolean;
  isCardio: boolean;
  isBeginnerSafe: boolean;
  isIntermediatePro: boolean;
  musclesUsed: string[];
}

interface WorkoutPlan {
  id: number;
  title: string;
  description: string;
  rating: number;
  exerciseIds: number[];
  duration: number;
  muscleGroups: string[]; // New field for muscle groups
  equipmentRequired: string[]; // New field for equipment
}

interface WorkoutPlansProps {
  workoutPlansData: WorkoutPlan[];
  exercisesData: Exercise[];
}

const muscleGroupOptions = ["Chest", "Back", "Legs", "Shoulders", "Arms", "Core", "Full Body"];
const equipmentOptions = ["Dumbbells", "Barbell", "Kettlebell", "Bodyweight", "Resistance Bands", "Machines"];

const WorkoutPlans: React.FC<WorkoutPlansProps> = ({ workoutPlansData, exercisesData }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMuscleGroup, setSelectedMuscleGroup] = useState<string | ''>(''); // State for muscle group filter
  const [selectedEquipment, setSelectedEquipment] = useState<string | ''>(''); // State for equipment filter

  const navigate = useNavigate(); // Initialize the navigate function

  // Function to get exercise details based on IDs from workout plan
  const getExerciseDetailsById = (exerciseIds: number[]) => {
    return exerciseIds
      .map((id) => exercisesData.find((exercise) => exercise.id === id))
      .filter((exercise): exercise is Exercise => !!exercise); // Filter out undefined
  };

  // Function to handle card click and navigate to the progress page
  const handleTrackProgress = (workoutId: number) => {
    navigate(`/track-progress/${workoutId}`);
  };

  // Filter workouts based on the search term, muscle group, and equipment selected by the user
  const filteredWorkouts = workoutPlansData.filter((workout) => {
    const matchesSearchTerm = workout.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesMuscleGroup = selectedMuscleGroup === '' || workout.muscleGroups.includes(selectedMuscleGroup);
    const matchesEquipment = selectedEquipment === '' || workout.equipmentRequired.includes(selectedEquipment);

    return matchesSearchTerm && matchesMuscleGroup && matchesEquipment;
  });

  return (
    <div>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Search Workouts"
            variant="outlined"
            fullWidth
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Muscle Group</InputLabel>
            <Select
              value={selectedMuscleGroup}
              onChange={(e) => setSelectedMuscleGroup(e.target.value as string)}
              label="Muscle Group"
            >
              <MenuItem value="">All Muscle Groups</MenuItem>
              {muscleGroupOptions.map((group) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Equipment</InputLabel>
            <Select
              value={selectedEquipment}
              onChange={(e) => setSelectedEquipment(e.target.value as string)}
              label="Equipment"
            >
              <MenuItem value="">All Equipment</MenuItem>
              {equipmentOptions.map((equipment) => (
                <MenuItem key={equipment} value={equipment}>
                  {equipment}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {filteredWorkouts.map((workout) => {
          // Fetch the exercises for each workout using the exercise IDs
          const exercises = getExerciseDetailsById(workout.exerciseIds);

          return (
            <Grid item xs={12} sm={6} md={3} key={workout.id}>
              <WorkoutCard
                title={workout.title}
                description={workout.description}
                exercises={exercises} // Pass exercise details to the WorkoutCard
                duration={workout.duration}
                onClick={() => handleTrackProgress(workout.id)} // Add onClick handler to navigate
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default WorkoutPlans;
