import React from 'react';
import { Typography } from '@mui/material';
import PageContainer from '../components/PageContainer';
import WorkoutPlans from '../components/WorkoutPlans';
import workoutPlansData from '../workout_plans.json';
import exercisesData from '../exercises.json';

const workoutPlans = workoutPlansData as any[];
const exercises = exercisesData as any[];

export default function WorkoutsPage() {
  return (
    <PageContainer>
      <Typography variant="h1">Workout Plans</Typography>
      <WorkoutPlans workoutPlansData={workoutPlans} exercisesData={exercises} />
    </PageContainer>
  );
}
