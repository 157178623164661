import React from 'react'

interface TextParagraphProps {
    title: string;
    paragraph: string;
  }

export default function TextParagraph({ title, paragraph }: TextParagraphProps) {

      const textContainerStyle: React.CSSProperties = {
        // padding: 20,
      };

      return (
          <div className="text-content" style={textContainerStyle}>
            <h2>{title}</h2>
            <p>{paragraph}</p>
          </div>
      );

  };