import { Box, IconButton, Menu, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { NavButton } from "./Header";
import { Link } from "react-router-dom";

interface HeaderMobileProps {
  pages: {
          label: string, 
          url: string
  }[];
  handleCloseNavMenu: () => void;
  handleOpenNavMenu: (event: React.MouseEvent<HTMLElement>) => void;
  anchorElNav: null | HTMLElement;
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({pages, handleCloseNavMenu, handleOpenNavMenu, anchorElNav}) => {

        return (
            <Box margin="8px" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
               {/* <Avatar alt="Fraser Willox" src={DisplayPic} /> */}
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
              <NavButton
                key={page.url}
                onClick={handleCloseNavMenu}
              >
              <Box key={page.url}  >
                <Link to={page.url}>
                  <Typography textAlign="center" color="black">{page.label}</Typography>
                </Link>
              </Box>
              </NavButton>
            ))}
            </Menu>
          </Box>
        )

};

export default HeaderMobile;