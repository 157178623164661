import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';
import Cookies from 'js-cookie';

const CenteredContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const Login: React.FC<{ onLogin: () => void }> = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = () => {
    if (username === 'user1' && password === 'password1') {
      setError('');
      // Set a cookie with a 30-day expiration
      Cookies.set('authToken', 'yourAuthToken', { expires: 30 });
      onLogin();
    } else {
      setError('Incorrect username or password');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <CenteredContainer maxWidth="xs">
      <Grid container spacing={2} onKeyPress={handleKeyPress}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">Login To Gym Progress</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="error" align="center">{error}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" color="primary" onClick={handleLogin}>Login</Button>
        </Grid>
      </Grid>
    </CenteredContainer>
  );
};

export default Login;
