// AppRouter.tsx
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import  TopBanner  from './components/Header';
import HomePage from './pages/HomePage';
import WorkoutsPage from './pages/WorkoutsPage';
import ProgressPage from './pages/ProgressPage';
import ExercisesPage from './pages/ExercisesPage';
import Footer from './components/Footer';
import CustomWorkoutsPage from './pages/CustomWorkoutsPage';
import PopularWorkoutsPage from './pages/PopularWorkoutsPage';
import TrackProgressPage from './pages/TrackProgressPage';
import MyProgressPage from './pages/MyProgressPage';
import CookiesPage from './pages/CookiesPage';
import FindPTPage from './pages/FindPTPage';
import PTDetailPage from './pages/PTDetailPage';
import StorePage from './pages/StorePage';

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <TopBanner/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/popular-workouts" element={<PopularWorkoutsPage />} />
        <Route path="/workouts" element={<WorkoutsPage />} />
        <Route path="/workouts/custom-workout" element={<CustomWorkoutsPage />} />
        <Route path="/track-progress/:workoutId" element={<TrackProgressPage />} />
        <Route path="/store" element={<StorePage/>} />
        <Route path="/my-progress" element={<MyProgressPage />} />
        <Route path="/find-a-pt" element={<FindPTPage />} />
        <Route path="/trainer/:trainerId" element={<PTDetailPage/>} />
        <Route path="/exercises" element={<ExercisesPage />} />
        <Route path="/progress" element={<ProgressPage />} />
        <Route path="/cookies" element={<CookiesPage />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
};

export default AppRouter;
