import React from 'react';

interface BMIProps {
    bmi: number;
    sex: string;  // 'male', 'female', 'prefer not to say'
    age: number;
    height: number;  // in centimeters
    weight: number;  // in kilograms
}

const BMIComponent: React.FC<BMIProps> = ({ bmi, sex, age, height, weight }) => {
    const getBMIColor = (bmi: number) => {
        if (bmi < 18.5) return '#F53939'; // Underweight
        if (bmi >= 18.5 && bmi <= 24.9) return '#49C265'; // Healthy Weight
        if (bmi >= 25 && bmi <= 29.9) return '#F7D31F'; // Overweight
        return '#F77E1C'; // Obese
    };

    const getBMIText = (bmi: number) => {
        if (bmi < 18.5) return 'UNDERWEIGHT';
        if (bmi >= 18.5 && bmi <= 24.9) return 'HEALTHY WEIGHT';
        if (bmi >= 25 && bmi <= 29.9) return 'OVERWEIGHT';
        return 'OBESE';
    };

    const calculateHealthyWeightRange = (height: number) => {
        const heightInMeters = height / 100;
        const minWeight = (18.5 * heightInMeters * heightInMeters).toFixed(1);
        const maxWeight = (24.9 * heightInMeters * heightInMeters).toFixed(1);
        return `${minWeight}kg - ${maxWeight}kg`;
    };

    const healthyWeightRange = calculateHealthyWeightRange(height);
    const barColor = getBMIColor(bmi);
    const bmiText = getBMIText(bmi);
    const bmiPercentage = ((bmi - 15) / (40 - 15)) * 100;

    return (
        <div className="bmi-component" style={{ textAlign: 'center', maxWidth: '600px', margin: '0 auto' }}>
            <h2>Your BMI = {bmi.toFixed(1)}</h2>
            <div className="bmi-bar" style={{ position: 'relative', height: '40px', background: 'linear-gradient(to right, #F53939, #49C265, #F7D31F, #F77E1C)', borderRadius: '20px' }}>
                <div style={{ position: 'absolute', left: `${bmiPercentage}%`, top: '-15px', transform: 'translateX(-50%)', color: '#000' }}>
                    ▼
                </div>
            </div>
            <p style={{ marginTop: '10px', fontSize: '1.5em', color: barColor }}>
                {bmiText}
            </p>
            <div style={{ marginTop: '10px', fontSize: '0.9em', color: '#555' }}>
                <p>Healthy weight range for this height: {healthyWeightRange}</p>
                <p>Sex: {sex} Age: {age} Height: {height}cm Weight: {weight}kg</p>
            </div>
        </div>
    );
};

export default BMIComponent;
