import Container from '@mui/material/Container';
import React from 'react'
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';


const OuterBox = styled(Box)<{ $backgroundImage: string }>`
  background-image: ${({ $backgroundImage }) => (`url(${$backgroundImage})`)};
  position: relative;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: left;
  min-height: 350px; // Set an appropriate minimum height
  border-radius: 6px;
`;

const InnerBox = styled(Box)`
  background-color: rgba(0, 0, 0, 0.2); /* Darken the background image */
  min-height: 350px; /* Set an appropriate minimum height */
  border-radius: 6px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.0);
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: white;
  transition: transform 0.2s;
`;

const TextBox = styled(Box)`
  padding-top: 20px;
  padding-bottom: 20px;
`;



interface TextImageBackgroundProps {
    backgroundImage: string;
    title: string;
    subTitle: string;
    linkUrl: string;
  }

export default function TextImageLink({ backgroundImage, title, subTitle, linkUrl }: TextImageBackgroundProps) {

      return (
        <OuterBox $backgroundImage={backgroundImage}>
          <InnerBox>
            <Container maxWidth="xl">
              <LinkStyled to={linkUrl}>
                <TextBox>
                  <h1>{title}</h1>
                  <p>{subTitle}</p>
                </TextBox>
              </LinkStyled>
            </Container>
          </InnerBox>
        </OuterBox>
      );
  };