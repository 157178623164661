import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppRouter from './AppRouter';
import theme from './theme';
import TermsAndConditionsPopup from './components/TermsAndConditionsPopup';
import Login from './components/Login';
import Cookies from 'js-cookie'; // Import Cookies

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  useEffect(() => {
    const isAccepted = localStorage.getItem('acceptedTerms');
    if (!isAccepted) {
      setShowPopup(true);
    } else {
      setAcceptedTerms(true);
    }

    const authToken = Cookies.get('authToken'); // Check for auth token
    if (authToken) {
      setLoggedIn(true);
    }
  }, []);

  const handleAcceptTerms = () => {
    localStorage.setItem('acceptedTerms', 'true');
    setAcceptedTerms(true);
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {showPopup && (
          <TermsAndConditionsPopup
            open={showPopup}
            onClose={handleClosePopup}
            onAccept={handleAcceptTerms}
          />
        )}
        {!loggedIn && <Login onLogin={handleLogin} />}
        {loggedIn && <AppRouter />}
      </div>
    </ThemeProvider>
  );
}

export default App;
